
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  nextTick,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment-timezone";

export default defineComponent({
  name: "SignUp",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let timezones = computed(() => {
      return store.getters[Modules.MASTER + "getTimezones"];
    });

    const timezone = ref("");

    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      name: Yup.string().required().label("Full Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string()
        .min(6)
        .max(15)
        .required()
        .matches(
          /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
          "Please choose a stronger password"
        )
        .label("Password"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      timezone: Yup.string().required().label("Timezone"),
      toc: Yup.bool().required("Accept Terms and conditions is required"),
    });

    onMounted(() => {
      defaultTimezone();
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    let defaultTimezone = () => {
      if (Object.keys(timezones.value).length !== 0) {
        timezone.value =
          timezones.value.filter((e) => e.code === moment.tz.guess()).length > 0
            ? moment.tz.guess()
            : "";
      }
    };

    watch(timezones, (value) => {
      defaultTimezone();
    });

    const onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.PURGE_AUTH);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.REGISTER, values)
        .then(() => {
          Swal.fire({
            text: "You have successfully created new account!\n\nPlease check your email for a verification link.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            // Go to page after successfully login
            router.push({ name: "signIn" });
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    return {
      webURL: process.env.VUE_APP_WEB_URL,
      timezones,
      timezone,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
